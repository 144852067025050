import { Box, Button } from "@chakra-ui/react";
import * as React from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

interface Props {
  startDate: Date;
  endDate: Date;
  onChangeDate: (payload: { start: Date; end: Date }) => void;
}

function TntDataRangePicker({ startDate, endDate, onChangeDate }: Props) {
  const [start, setStart] = React.useState(startDate);
  const [end, setEnd] = React.useState(endDate);
  const { t } = useTranslation();

  const handleComplete = () => {
    onChangeDate({
      start,
      end,
    });
  };

  React.useEffect(() => {
    setStart(startDate);
    setEnd(endDate);
  }, [endDate, startDate]);

  return (
    <Box display="flex" gap="0.5rem">
      <ReactDatePicker
        className="custom-date-picker"
        selected={start}
        onChange={(date: Date) => setStart(date)}
        selectsStart
        startDate={start}
        endDate={end}
        showTimeInput
        dateFormat="dd/MM/yyyy HH:mm:ss"
      />
      <ReactDatePicker
        className="custom-date-picker"
        selected={end}
        onChange={(date: Date) => setEnd(date)}
        selectsEnd
        startDate={start}
        endDate={end}
        minDate={start}
        showTimeInput
        dateFormat="dd/MM/yyyy HH:mm:ss"
      />
      <Button colorScheme="blue" onClick={handleComplete}>
        {t("filter")}
      </Button>
    </Box>
  );
}

export default TntDataRangePicker;
