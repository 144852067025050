import MainLayout from "components/MainLayout";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { profileAction } from "state/auth";

function HomePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const profile = localStorage.getItem("PROFILE");
    if (!profile) {
      dispatch(profileAction());
    }
  }, [dispatch]);

  return (
    <MainLayout pageTitle={t("dashboard")}>
      <p>{"Coming soon..."}</p>
    </MainLayout>
  );
}

export default HomePage;
