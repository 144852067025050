import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import axios from "axios";
import { setLoading, showToast } from "state/common";

let store: ToolkitStore;

export const injectStore = (_store: ToolkitStore) => {
  store = _store;
};

const externalGW = axios.create({
  baseURL: process.env.REACT_APP_EXTERNAL_GW, // Replace with your API base URL
});

// Request interceptor
externalGW.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("ACCOUNT_INFO") || null;
    const userInfo = user ? JSON.parse(user) : null;
    const accessToken = userInfo ? userInfo?.accessToken : null;
    const refreshToken = userInfo?.refreshToken;
    store.dispatch(setLoading(true));
    if (accessToken) {
      if (config.headers)
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    throw error;
  }
);

// Response interceptor
externalGW.interceptors.response.use(
  (response) => {
    store.dispatch(setLoading(false));
    store.dispatch(
      showToast({
        status: "success",
        title: `Status: ${response.data?.status || -1}`,
        message: response.data.message || "",
      })
    );
    return response;
  },
  (error) => {
    const { response } = error;
    store.dispatch(setLoading(false));
    store.dispatch(
      showToast({
        status: "error",
        title: `Status: ${response.data?.status || -1}`,
        message: response.data.message || "",
      })
    );
    if (response.data?.status === 401) {
      window.location.href = "/login";
    }
    if(response.data?.status === 403){
      window.location.href = "/error-403";
    }
    throw error;
  }
);

export default externalGW;
