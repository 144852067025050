import MainLayout from "components/MainLayout";
import { DeviceStatusContainer } from "containers";
import React from "react";
import { useTranslation } from "react-i18next";

function DeviceStatusPage() {
  const { t } = useTranslation();

  return (
    <MainLayout pageTitle={t("device-status")}>
      <DeviceStatusContainer />
    </MainLayout>
  );
}

export default React.memo(DeviceStatusPage);
