import { Box, Checkbox, HStack } from "@chakra-ui/react";
import { TntSelect, TntTable, TntTextBox } from "components/atoms";
import {
  DATE_TIME_FORMAT,
  DeviceActivityStatusOption,
  DeviceName,
  DeviceNameOption,
} from "constants/common";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { commonSelector, listClubAction } from "state/common";
import {
  getACLogAction,
  logSelector,
  onChangeCurrentPage,
  onChangeLimit,
} from "state/log";
import { isNull } from "lodash";
import TntDeviceStatusTag from "components/atoms/TntDeviceStatusTag";
import { useDebounce } from "hooks/useDebounce";
import { ImEnter } from "react-icons/im";
import { ImExit } from "react-icons/im";
import { FaFingerprint } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

export function DeviceActivityHistoryTab() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { acLogList, itemPerPage, currentPage } = useAppSelector(logSelector);
  const { listClub } = useAppSelector(commonSelector);
  const location = useLocation();
  const [pin, setPin] = React.useState<string>("");
  const debounceReq = useDebounce(() => {
    navigate("/logs/device-activity/history", {
      state: {
        clubId: location.state?.clubId || "",
        deviceId: location.state?.deviceId || "",
        status: location.state?.status || "",
        pin: pin,
        isBooting: location.state?.isBooting || "",
      },
    });
  });

  // FUNCTION
  const handelChangeClub = (newValue: string | number) => {
    navigate("/logs/device-activity/history", {
      state: {
        clubId: newValue,
        deviceId: location.state?.deviceId || "",
        status: location.state?.status || "",
        pin: location.state?.pin || "",
        isBooting: location.state?.isBooting || "",
      },
    });
    handleChangePage(1);
  };

  const handelChangeDevice = (newValue: string | number) => {
    navigate("/logs/device-activity/history", {
      state: {
        clubId: location.state?.clubId || "",
        deviceId: newValue,
        status: location.state?.status || "",
        pin: location.state?.pin || "",
        isBooting: location.state?.isBooting || "",
      },
    });
    handleChangePage(1);
  };

  const handelChangeStatus = (newValue: string | number) => {
    navigate("/logs/device-activity/history", {
      state: {
        clubId: location.state?.clubId || "",
        deviceId: location.state?.deviceId || "",
        status: newValue,
        pin: location.state?.pin || "",
        isBooting: location.state?.isBooting || "",
      },
    });
    handleChangePage(1);
  };

  const handelChangeIsBooting = (newValue: boolean | number) => {
    navigate("/logs/device-activity/history", {
      state: {
        clubId: location.state?.clubId || "",
        deviceId: location.state?.deviceId || "",
        status: location.state?.status || "",
        pin: location.state?.pin || "",
        isBooting: newValue,
      },
    });
    handleChangePage(1);
  };

  const handelChangePin = (newValue: string) => {
    setPin(newValue);
    handleChangePage(1);
    debounceReq();
  };

  const handleChangePage = (page: number) => {
    dispatch(onChangeCurrentPage(page));
  };

  const handleLimit = (limit: number) => {
    dispatch(onChangeLimit(limit));
  };

  const isErrorStatus = (
    isQR: number | null,
    isKeyboard: number | null,
    isFinger: number | null,
    device: string,
    updatedAt: string | null
  ) => {
    if (device === DeviceName.CHECKOUT) {
      if ((isQR === 1 || isKeyboard === 1) && !isNull(updatedAt)) return false;
    } else {
      if (
        (isQR === 1 && isFinger === 1) ||
        (isKeyboard === 1 && isFinger === 1)
      )
        return false;
    }
    return true;
  };

  // LIFE CYCLE
  React.useEffect(() => {
    dispatch(listClubAction());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      getACLogAction({
        limit: itemPerPage,
        page: currentPage,
        deviceType: location.state?.deviceId || "",
        clubId: location.state?.clubId || "",
        status: location.state?.status || "",
        pin: location.state?.pin || "",
        isBooting: location.state?.isBooting || "",
      })
    );
  }, [
    currentPage,
    dispatch,
    itemPerPage,
    location.state?.clubId,
    location.state?.deviceId,
    location.state?.isBooting,
    location.state?.pin,
    location.state?.status,
  ]);

  return (
    <Box>
      <HStack borderBottom="2px solid var(--gray-01)" pb="10px">
        <TntTextBox
          value={pin}
          bg="transparent"
          placeholder={t("input-pin-placeholder")}
          borderColor="blue.600"
          boxProps={{
            w: "200px",
          }}
          onChange={(e) => {
            handelChangePin(e.target.value);
          }}
        />
        <TntSelect
          value={location.state?.clubId || ""}
          placeHolder={t("all-club")}
          onSelectOption={handelChangeClub}
          options={listClub?.map((ele) => ({
            value: ele.id || -1,
            label: (i18n.language === "en" ? ele?.nameEn : ele?.nameVi) || "",
          }))}
        />
        <TntSelect
          value={location.state?.deviceId || ""}
          placeHolder={t("all-device")}
          onSelectOption={handelChangeDevice}
          options={DeviceNameOption}
        />
        <TntSelect
          value={location.state?.status || ""}
          placeHolder={t("all-status")}
          onSelectOption={handelChangeStatus}
          options={DeviceActivityStatusOption}
        />
        <Checkbox
          defaultChecked={location.state?.isBooting}
          onChange={(e) => {
            handelChangeIsBooting(e.target.checked);
          }}
          fontWeight="bold"
        >
          Booting
        </Checkbox>
      </HStack>
      <Box mt="16px">
        <TntTable
          columns={[
            t("id"),
            "pin",
            t("account-id"),
            t("name"),
            t("club"),
            t("device"),
            t("booting"),
            t("status"),
            t("note"),
            "QR",
            "KEYBOARD",
            "FINGER",
            t("created-at"),
            t("updated-at"),
          ]}
          rows={acLogList.result.map((ele) => {
            return [
              ele.id,
              ele.pin,
              ele?.user ? ele.user.accountId : "-",
              ele?.user ? `${ele.user.firstName} ${ele.user.lastName}` : "-",
              i18n.language === "en" ? ele.clubNameEn : ele.clubNameVi,
              ele.deviceType === DeviceName.CHECKIN ? (
                <ImEnter
                  fontSize="26px"
                  color="var(--chakra-colors-green-400)"
                  style={{ margin: "auto" }}
                />
              ) : ele.deviceType === DeviceName.CHECKOUT ? (
                <ImExit
                  fontSize="26px"
                  color="var(--chakra-colors-red-400)"
                  style={{ margin: "auto" }}
                />
              ) : (
                <FaFingerprint
                  fontSize="26px"
                  color="var(--chakra-colors-gray-500)"
                  style={{ margin: "auto" }}
                />
              ),
              <TntDeviceStatusTag
                isError={!Boolean(ele.isBooting)}
                isEmpty={!Boolean(ele.isBooting)}
              />,
              <TntDeviceStatusTag
                isError={isErrorStatus(
                  ele.isQr,
                  ele.isKeyboard,
                  ele.isFinger,
                  ele.deviceType,
                  ele.updatedAt
                )}
                isEmpty={isNull(ele.updatedAt)}
              />,
              ele.note,
              <TntDeviceStatusTag
                isError={ele.isQr !== 1}
                isEmpty={isNull(ele.isQr)}
              />,
              <TntDeviceStatusTag
                isError={ele.isKeyboard !== 1}
                isEmpty={isNull(ele.isKeyboard)}
              />,
              <TntDeviceStatusTag
                isError={ele.isFinger !== 1}
                isEmpty={isNull(ele.isFinger)}
              />,
              moment(ele.createdAt).format(DATE_TIME_FORMAT),
              ele.updatedAt
                ? moment(ele.updatedAt).format(DATE_TIME_FORMAT)
                : "-",
            ];
          })}
          total={acLogList.total}
          itemPerPage={itemPerPage}
          currentPage={currentPage}
          onChangePage={handleChangePage}
          onChangeLimit={handleLimit}
        />
      </Box>
    </Box>
  );
}
