import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

export default function TntButton({ children, ...res }: ButtonProps) {
  return (
    <Button
      fontSize="var(--font-md)"
      padding="11px 32px"
      bg="var(--blue-02)"
      color="var(--white-01)"
      transition="0.3s"
      w="100%"
      colorScheme="blue"
      {...res}
    >
      {children}
    </Button>
  );
}
