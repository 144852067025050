import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Img,
  Switch,
  Text,
} from "@chakra-ui/react";
import {
  IoLogOutOutline,
  IoNotificationsOutline,
  IoReturnDownForward,
  IoChevronDownOutline,
} from "react-icons/io5";
import * as React from "react";
import { sidebarList } from "constants/common";
import { useTranslation } from "react-i18next";
import { useGoto } from "hooks";
import { useDispatch } from "react-redux";
import { authSelector, logout } from "state/auth";
import { useAppSelector } from "hooks/useAppSelector";
import { commonSelector, onClose } from "state/common";

function Sidebar() {
  const { t, i18n } = useTranslation();
  const { isOpenSidebar } = useAppSelector(commonSelector);
  const { isLogin } = useAppSelector(authSelector);
  const { goto } = useGoto();
  const [isOpenIdx, setIsOpenIdx] = React.useState(-1);
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState("");

  React.useEffect(() => {
    const profile = localStorage.getItem("PROFILE");
    if (profile) {
      const info = JSON.parse(profile);
      setUsername(`${info.firstName} ${info.lastName}`);
    }
  }, [isLogin]);

  const handleClose = () => {
    dispatch(onClose());
  };

  const handleChangeOpen = (idx: number) => () => {
    setIsOpenIdx(isOpenIdx === idx ? -1 : idx);
  };

  const handleSwitchLanguage = () => {
    const newLanguage = i18n.language === "vi" ? "en" : "vi";
    localStorage.setItem("language", newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Drawer placement="left" isOpen={isOpenSidebar} onClose={handleClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          <Box w="100%" h="50px" display="flex" justifyContent="center">
            <Img
              h="100%"
              w="auto"
              src="https://thenewgym.vn/wp-content/uploads/2022/12/logo.png"
            />
          </Box>
        </DrawerHeader>
        <DrawerBody>
          <Box w="100%" minW="256px" bg="var(--white-01)" position="relative">
            <HStack display="flex" justifyContent="center">
              <Flex
                w="48px"
                h="48px"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
              >
                <Avatar name={username} border="2px solid var(--blue-03)" />
              </Flex>
              <Flex
                w="48px"
                h="48px"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                // border="2px solid var(--blue-03)"
                borderRadius="24px"
                position="relative"
              >
                <IoNotificationsOutline
                  fontSize="24px"
                  color="var(--black-01)"
                />
                <Text
                  position="absolute"
                  bg="var(--red-01)"
                  color="var(--white-01)"
                  borderRadius="16px"
                  fontSize="12px"
                  w="18px"
                  h="18px"
                  textAlign="center"
                  right="6px"
                  top="6px"
                >
                  9
                </Text>
              </Flex>
              <Flex
                w="48px"
                h="48px"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                // border="2px solid var(--blue-03)"
                borderRadius="24px"
                onClick={handleLogout}
              >
                <IoLogOutOutline fontSize="24px" />
              </Flex>
            </HStack>
            <Box mt="16px">
              {sidebarList.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Flex
                      p="12px 8px"
                      mb="8px"
                      cursor="pointer"
                      transition="0.3s"
                      _hover={{
                        bg: "var(--white-02)",
                      }}
                      position="relative"
                      alignItems="center"
                      bg={
                        isOpenIdx === idx
                          ? "var(--white-02)"
                          : "var(--white-01)"
                      }
                      onClick={
                        item.child && item.child.length > 0
                          ? handleChangeOpen(idx)
                          : () => {
                              goto(item.destination);
                              handleClose();
                            }
                      }
                    >
                      {item.icon}
                      <Text
                        ml="8px"
                        fontSize="16px"
                        fontWeight="500"
                        color="var(--black-01)"
                      >
                        {t(item.label)}
                      </Text>
                      {item.child && item.child.length > 0 && (
                        <Box
                          position="absolute"
                          right="8px"
                          transition="0.3s"
                          transform={`rotate(${
                            isOpenIdx === idx ? 180 : 0
                          }deg)`}
                        >
                          <IoChevronDownOutline fontSize="24px" />
                        </Box>
                      )}
                    </Flex>
                    <Collapse in={isOpenIdx === idx} animateOpacity>
                      {item.child &&
                        item.child.length > 0 &&
                        item.child?.map((child, childIdx) => {
                          return (
                            <Flex
                              key={childIdx}
                              p="8px 12px"
                              mb="8px"
                              cursor="pointer"
                              transition="0.3s"
                              _hover={{
                                bg: "var(--white-02)",
                              }}
                              onClick={() => {
                                goto(child.destination);
                                handleClose();
                              }}
                            >
                              <IoReturnDownForward fontSize="20px" />
                              <Text
                                ml="8px"
                                fontSize="14px"
                                fontWeight="500"
                                color="var(--black-01)"
                              >
                                {t(child.label)}
                              </Text>
                            </Flex>
                          );
                        })}
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex
            borderTop="1px solid var(--gray-01)"
            w="100%"
            justifyContent="center"
            mt="16px"
            position="absolute"
            left="0"
            bottom="0"
            alignItems="center"
            gap="16px"
          >
            <Image src="/images/usa.png" w="32px" h="auto" />
            <Switch
              colorScheme="blue"
              size="md"
              isChecked={i18n.language === "vi"}
              onChange={handleSwitchLanguage}
            />
            <Image src="/images/vietnam.png" w="32px" h="auto" />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default React.memo(Sidebar);
