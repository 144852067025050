import { createStandaloneToast } from "@chakra-ui/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClub, IToast } from "interfaces/common";
import { listClubAction } from "./actions";

const { toast } = createStandaloneToast();

interface State {
  isLoading: boolean;
  isOpenSidebar: boolean;
  listClub: IClub[];
}

const initialState: State = {
  isLoading: false,
  isOpenSidebar: false,
  listClub: [],
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    showToast(_state, action: PayloadAction<IToast>) {
      if (action.payload.message)
        toast({
          title: action.payload.title,
          description: action.payload.message,
          status: action.payload.status || "info",
          duration: 2000,
          isClosable: true,
          position: "top-right",
          variant: "left-accent",
        });
    },
    onOpen(state) {
      state.isOpenSidebar = true;
    },
    onClose(state) {
      state.isOpenSidebar = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listClubAction.fulfilled, (state, action) => {
      const data = action.payload?.data ? action.payload.data : {};
      state.listClub = data;
    });
  },
});

export const { setLoading, showToast, onOpen, onClose } = commonSlice.actions;
export default commonSlice.reducer;
