import { Box, Img } from "@chakra-ui/react";
import * as React from "react";

function ForbiddenPage() {
  return <Box w="100vw" h="100vh" display="flex" justifyContent="center" alignItems="center">
    <Img src="/images/bg-04.jpg"/>
  </Box>;
}

export default ForbiddenPage;
