import { createSlice } from "@reduxjs/toolkit";
import { loginAction, profileAction } from "./actions";

interface State {
  isLogin: boolean;
}

const initialState: State = {
  isLogin: false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.isLogin = false;
      localStorage.removeItem("ACCOUNT_INFO");
      localStorage.removeItem("PROFILE");
      window.location.href = "/login";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.fulfilled, (state, action) => {
      const data = action.payload?.data ? action.payload.data : {};
      localStorage.setItem("ACCOUNT_INFO", JSON.stringify(data));
      localStorage.removeItem("PROFILE");
      window.location.href = "/";
    });
    builder.addCase(profileAction.fulfilled, (state, action) => {
      const data = action.payload?.data ? action.payload.data : {};
      localStorage.setItem("PROFILE", JSON.stringify(data));
      state.isLogin =  true;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
