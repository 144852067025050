import {
  Box,
  BoxProps,
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import * as React from "react";

interface Props {
  label?: string;
  errorMessage?: string;
  boxProps?: BoxProps;
}

function TntTextBox({
  label,
  type,
  errorMessage,
  boxProps,
  ...rest
}: Props & InputProps) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Box w="100%" h="auto" {...boxProps}>
      {label && (
        <Text fontSize="14px" fontWeight="400" color="var(--black-01)" mb="8px">
          {label}
        </Text>
      )}
      <InputGroup>
        <Input
          variant="outline"
          fontSize="16px"
          fontWeight="400"
          color="var(--black-01)"
          bg="var(--gray-01)"
          p="12px 16px"
          type={show || type !== "password" ? "text" : "password"}
          borderColor={errorMessage ? "var(--red-01)" : "var(--gray-02)"}
          {...rest}
        />
        {type === "password" && (
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? <IoEyeOff /> : <IoEye />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {errorMessage && (
        <Text fontSize="12px" fontWeight="400" color="var(--red-01)" mt="5px">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}

export default TntTextBox;
