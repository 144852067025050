import { Box } from "@chakra-ui/react";
import { TntStatusBlock, TntTable } from "components/atoms";
import { useAppSelector } from "hooks/useAppSelector";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { getCurrentStatusAction, logSelector } from "state/log";
import _ from "lodash";
import { DeviceName } from "constants/common";

interface Props {}

export function DeviceStatusCurrentTab(props: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { deviceCurrentStatusList } = useAppSelector(logSelector);

  // LIFE CYCLE
  React.useEffect(() => {
    dispatch(getCurrentStatusAction());
  }, [dispatch]);

  const tableRow = React.useMemo(() => {
    if (!deviceCurrentStatusList || deviceCurrentStatusList.length === 0)
      return [];
    const data = _.groupBy(deviceCurrentStatusList, "clubId");
    const keys = Object.keys(data);
    const result = keys?.map((clubId) => {
      const enroll = data[clubId].find(
        (device) => device.deviceName === DeviceName.ENROLL
      );
      const checkin = data[clubId].find(
        (device) => device.deviceName === DeviceName.CHECKIN
      );
      const checkout = data[clubId].find(
        (device) => device.deviceName === DeviceName.CHECKOUT
      );
      const sidedoor = data[clubId].find(
        (device) => device.deviceName === DeviceName.SIDEDOOR
      );
      return [
        i18n.language === "en"
          ? data[clubId][0].club?.nameEn
          : data[clubId][0].club?.nameVi,
        <TntStatusBlock status={enroll?.status} payload={enroll?.payload} />,
        <TntStatusBlock status={checkin?.status} payload={checkin?.payload} />,
        <TntStatusBlock
          status={checkout?.status}
          payload={checkout?.payload}
        />,
        <TntStatusBlock
          status={sidedoor?.status}
          payload={sidedoor?.payload}
        />,
      ];
    });
    return result;
  }, [deviceCurrentStatusList, i18n.language]);

  return (
    <Box>
      <Box mt="16px">
        <TntTable
          columns={[t("club"), "ENROLL", "CHECKIN", "CHECKOUT", "SIDEDOOR"]}
          rows={tableRow}
          havePagination={false}
        />
      </Box>
    </Box>
  );
}
