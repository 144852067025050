import { Box, Flex, Img, VStack } from "@chakra-ui/react";
import { TntHeading, TntTextBox } from "components/atoms";
import TntButton from "components/atoms/TntButton";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginAction } from "state/auth";
import _ from "lodash";
import { AppDispatch } from "state";

interface Props {}

function LoginContainer(props: Props) {
  const { t } = useTranslation();
  const [dataAuth, setDataAuth] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });
  const dispatch = useDispatch<AppDispatch>();

  const handleChange =
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setDataAuth({
        ...dataAuth,
        [key]: event.target.value,
      });
    };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;
    const newError = { ...error };
    if (_.isEmpty(dataAuth.username)) {
      valid = false;
      newError.username = t("message.required-field");
    }
    if (_.isEmpty(dataAuth.password)) {
      valid = false;
      newError.password = t("message.required-field");
    }
    setError(newError);
    if (valid) {
      dispatch(loginAction(dataAuth));
    }
  };

  return (
    <Flex
      w="100vw"
      h="100vh"
      bg="rgba(255,255,255,0.6)"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        maxW="600px"
        w="100%"
        h="100%"
        padding="40px"
      >
        <Img position="absolute" zIndex="-1" bottom={0} w="900px" src="/images/bg-01.jpg" />
        <form onSubmit={handleLogin}>
          <TntHeading mb="48px" textAlign="center">
            {t("login")}
          </TntHeading>
          <VStack spacing="16px" w="100%">
            <TntTextBox
              label={t("email-address")}
              onChange={handleChange("username")}
              errorMessage={error.username}
            />
            <TntTextBox
              label={t("password")}
              type="password"
              onChange={handleChange("password")}
              errorMessage={error.password}
            />
            <TntButton w="150px" type="submit">
              {t("login")}
            </TntButton>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
}

export default LoginContainer;
