import { Box, Button, useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import Sidebar from "./molecules/Sidebar";
import { TntHeading } from "./atoms";
import { IoGridOutline } from "react-icons/io5";
import { onOpen } from "state/common";
import { useDispatch } from "react-redux";

interface Props {
  children: React.ReactNode;
  pageTitle?: string;
}

function MainLayout({ children, pageTitle }: Props) {
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(onOpen());
  };
  return (
    <Box display="flex" position="relative" h="100vh">
      <Button
        position="absolute"
        onClick={handleOpen}
        colorScheme="blue"
        borderRadius="0"
        borderBottomRightRadius="8px"
        zIndex={100}
      >
        <IoGridOutline fontSize="18px" />
      </Button>
      <Sidebar />
      <Box
        w="100vw"
        h="100vh"
        bg="var(--white-02)"
        p="24px"
        minW="600px"
        overflowX="auto"
      >
        <TntHeading mb="24px" mt="16px">
          {pageTitle}
        </TntHeading>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}

export default React.memo(MainLayout);
