import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getACLogAction,
  getCurrentStatusAction,
  getHistoryStatusAction,
} from "./actions";
import { IACLog, IDeviceStatus, IDeviceStatusHistory } from "interfaces/log";

interface State {
  acLogList: IACLog;
  deviceCurrentStatusList: IDeviceStatus[];
  deviceHistoryStatusList: IDeviceStatusHistory;
  itemPerPage: number;
  currentPage: number;
  deviceHistoryPerPage: number;
  deviceHistoryCurrentPage: number;
}

const initialState: State = {
  acLogList: { result: [], total: 0 },
  deviceCurrentStatusList: [],
  deviceHistoryStatusList: { result: [], total: 0 },
  itemPerPage: 50,
  currentPage: 1,
  deviceHistoryPerPage: 50,
  deviceHistoryCurrentPage: 1,
};

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    onChangeCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    onChangeLimit(state, action: PayloadAction<number>) {
      state.currentPage = 1;
      state.itemPerPage = action.payload;
    },
    onChangeHistoryCurrentPage(state, action: PayloadAction<number>) {
      state.deviceHistoryCurrentPage = action.payload;
    },
    onChangeHistoryLimit(state, action: PayloadAction<number>) {
      state.currentPage = 1;
      state.deviceHistoryPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getACLogAction.fulfilled, (state, action) => {
      state.acLogList = action.payload.data;
    });
    builder.addCase(getCurrentStatusAction.fulfilled, (state, action) => {
      state.deviceCurrentStatusList = action.payload.data;
    });
    builder.addCase(getHistoryStatusAction.fulfilled, (state, action) => {
      state.deviceHistoryStatusList = action.payload.data;
    });
  },
});

export const {
  onChangeCurrentPage,
  onChangeLimit,
  onChangeHistoryCurrentPage,
  onChangeHistoryLimit,
} = logSlice.actions;
export default logSlice.reducer;
