import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListClub } from "services/general.service";

export const listClubAction = createAsyncThunk(
  "common/list-club",
  async (thunkAPI) => {
    const response = await getListClub();
    return response.data;
  }
);
