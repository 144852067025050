import {
  Box,
  Img,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { formatNumber } from "utils/number";

interface Props {
  havePagination?: boolean;
  currentPage?: number;
  total?: number;
  itemPerPage?: number;
  onChangePage?: (newPage: number) => void;
  onChangeLimit?: (newLimit: number) => void;
  columns?: string[];
  rows?: any[][];
}

function TntTable({
  havePagination = true,
  currentPage = 1,
  total = 0,
  itemPerPage = 1,
  onChangePage = () => {},
  onChangeLimit = () => {},
  columns = [],
  rows = [],
}: Props) {

  const {t} = useTranslation();

  return (
    <TableContainer bg="white" borderRadius="8px" padding="8px">
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {columns.map((ele, idx) => {
              return (
                <Th key={idx} fontSize="14px" py="10px" textAlign="center">
                  {ele}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, ridx) => {
            return (
              <Tr key={ridx}>
                {row?.map((col, cidx) => {
                  return (
                    <Td key={cidx} textAlign="center">
                      {col}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {rows.length > 0 ? (
        havePagination && (
          <Box
            margin="auto"
            mt="16px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="14px">{t("total-record")}: {formatNumber(total)}</Text>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box mr="8px">
                <ResponsivePagination
                  maxWidth={300}
                  current={currentPage}
                  total={Math.ceil(total / itemPerPage)}
                  onPageChange={onChangePage}
                />
              </Box>
              <Select
                value={itemPerPage}
                h="32px"
                w="80px"
                fontSize="12px"
                mr="8px"
                onChange={(e) => onChangeLimit(Number(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
            </Box>
          </Box>
        )
      ) : (
        <Img w="300px" margin="auto" src="/images/bg-02.jpg" />
      )}
    </TableContainer>
  );
}

export default TntTable;
