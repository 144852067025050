import { createAsyncThunk } from "@reduxjs/toolkit";
import { ILogin } from "interfaces/auth";
import { login, profile } from "services/auth.service";

export const loginAction = createAsyncThunk(
  "auth/login",
  async (payload: ILogin, thunkAPI) => {
    const response = await login(payload);
    return response.data;
  }
);

export const profileAction = createAsyncThunk(
  "auth/profile",
  async (thunkAPI) => {
    const response = await profile();
    return response.data;
  }
);
