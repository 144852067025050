import { IACLogReq, IDeviceHistoryReq } from "interfaces/request";
import externalGW from "./axios.service";

export const getAccessControlLog = async (payload: IACLogReq) => {
  return await externalGW.get("/api/v1/access-control/log", {
    params: payload,
  });
};

export const getCurrentStatus = async () => {
  return await externalGW.get("/api/v1/log/device/status/current");
};

export const getHistoryStatus = async (payload: IDeviceHistoryReq) => {
  return await externalGW.get("/api/v1/log/device/status", {
    params: payload,
  });
};
