import { Box, Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

function DeviceActivityContainer() {
  const { t } = useTranslation();

  return (
    <Box>
      <Tabs size="md" variant="line">
        <TabList>
          <Tab
            as={NavLink}
            fontWeight="bold"
            textTransform="uppercase"
            to={"overview"}
            _focusVisible={{}}
          >
            {t("overview")}
          </Tab>
          <Tab
            as={NavLink}
            fontWeight="bold"
            textTransform="uppercase"
            to={"history"}
            _focusVisible={{}}
          >
            {t("activity-history")}
          </Tab>
        </TabList>
        <TabPanels mt="16px">
          <Outlet />
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default React.memo(DeviceActivityContainer);
