import { createAsyncThunk } from "@reduxjs/toolkit";
import { IACLogReq, IDeviceHistoryReq } from "interfaces/request";
import {
  getAccessControlLog,
  getCurrentStatus,
  getHistoryStatus,
} from "services/log.service";

export const getACLogAction = createAsyncThunk(
  "log/getACLogAction",
  async (payload: IACLogReq, _thunkAPI) => {
    const response = await getAccessControlLog(payload);
    return response.data;
  }
);

export const getCurrentStatusAction = createAsyncThunk(
  "log/getCurrentStatusLogAction",
  async (_thunkAPI) => {
    const response = await getCurrentStatus();
    return response.data;
  }
);

export const getHistoryStatusAction = createAsyncThunk(
  "log/getHistoryStatusLogAction",
  async (payload: IDeviceHistoryReq, _thunkAPI) => {
    const response = await getHistoryStatus(payload);
    return response.data;
  }
);
