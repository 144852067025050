import { NavigateOptions, useNavigate } from "react-router-dom";

export function useGoto() {
  const navigate = useNavigate();

  const goto = (des: string, option?: NavigateOptions) => {
    navigate(des, option);
  };

  const goback = () => {
    navigate(-1);
  };

  return { goto, goback };
}
