import * as React from 'react';

interface Props {
}

export function DeviceActivityOverviewTab (props: Props) {
  return (
    <div>
      Coming soon...
    </div>
  );
}
