import { Tag } from "@chakra-ui/react";
import * as React from "react";

interface Props {
  isError?: boolean;
  isEmpty?:boolean;
}

function TntDeviceStatusTag({ isError = false, isEmpty = false }: Props) {
  return <Tag bg={isEmpty ? "gray.300" :isError ? "red.300" : "green.300"}></Tag>;
}

export default TntDeviceStatusTag;
