import { Box, HStack } from "@chakra-ui/react";
import { TntSelect, TntStatusBlock, TntTable } from "components/atoms";
import TntDataRangePicker from "components/atoms/TntDataRangePicker";
import {
  DATE_TIME_FORMAT,
  DeviceNameOption,
  DeviceStatusOption,
} from "constants/common";
import { useAppSelector } from "hooks/useAppSelector";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { commonSelector, listClubAction } from "state/common";
import {
  getHistoryStatusAction,
  logSelector,
  onChangeHistoryCurrentPage,
  onChangeHistoryLimit,
} from "state/log";

export function DeviceStatusHistoryTab() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {
    deviceHistoryStatusList,
    deviceHistoryCurrentPage,
    deviceHistoryPerPage,
  } = useAppSelector(logSelector);
  const { listClub } = useAppSelector(commonSelector);
  const [clubId, setClubId] = React.useState<string | number>("");
  const [status, setStatus] = React.useState<string>("");
  const [deviceName, setDeviceName] = React.useState<string>("");
  const beginDate = new Date();
  beginDate.setHours(0, 0, 0, 0);
  const endDate = new Date();
  endDate.setHours(23, 59, 59);
  const [date, setDate] = React.useState({
    start: beginDate,
    end: endDate,
  });

  // FUNCTION
  const handelChangeClub = (newValue: string | number) => {
    setClubId(newValue);
    handleChangePage(1);
  };

  const handelChangeStatus = (newValue: string | number) => {
    setStatus(String(newValue));
    handleChangePage(1);
  };

  const handelChangeDevice = (newValue: string | number) => {
    setDeviceName(String(newValue));
    handleChangePage(1);
  };

  const handleChangePage = (page: number) => {
    dispatch(onChangeHistoryCurrentPage(page));
  };

  const handleLimit = (limit: number) => {
    dispatch(onChangeHistoryLimit(limit));
    handleChangePage(1);
  };

  const handleChangeDate = (payload: { start: Date; end: Date }) => {
    setDate(payload);
  };

  // LIFE CYCLE
  React.useEffect(() => {
    dispatch(listClubAction());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      getHistoryStatusAction({
        limit: deviceHistoryPerPage,
        ...(date.start ? { startDate: date.start.toISOString() } : {}),
        ...(date.end ? { endDate: date.end.toISOString() } : {}),
        page: deviceHistoryCurrentPage,
        ...(status ? { status: status } : {}),
        ...(deviceName ? { deviceName: deviceName } : {}),
        ...(clubId ? { clubId: clubId } : {}),
      })
    );
  }, [
    clubId,
    date.end,
    date.start,
    deviceHistoryCurrentPage,
    deviceHistoryPerPage,
    deviceName,
    dispatch,
    status,
  ]);

  return (
    <Box>
      <HStack borderBottom="2px solid var(--gray-01)" pb="10px">
        <TntSelect
          value={clubId}
          placeHolder={t("all-club")}
          onSelectOption={handelChangeClub}
          options={listClub?.map((ele) => ({
            value: ele?.id || -1,
            label: (i18n.language === "en" ? ele?.nameEn : ele?.nameVi) || "",
          }))}
        />
        <TntSelect
          value={status}
          placeHolder={t("all-status")}
          onSelectOption={handelChangeStatus}
          options={DeviceStatusOption}
        />
        <TntSelect
          value={deviceName}
          placeHolder={t("all-device")}
          onSelectOption={handelChangeDevice}
          options={DeviceNameOption}
        />
        <TntDataRangePicker
          startDate={date.start}
          endDate={date.end}
          onChangeDate={handleChangeDate}
        />
      </HStack>
      <Box mt="16px">
        <TntTable
          columns={[
            t("id"),
            t("club"),
            t("club-short"),
            t("device"),
            t("status"),
            t("time-response"),
            t("created-at"),
          ]}
          rows={deviceHistoryStatusList.result.map((ele) => {
            return [
              ele.id,
              i18n.language === "en" ? ele.club?.nameEn : ele.club?.nameVi,
              ele.club?.key,
              ele.deviceName,
              <TntStatusBlock status={ele.status} payload={ele.payload} />,
              ele.timeResponse,
              moment(ele.createdAt).format(DATE_TIME_FORMAT),
            ];
          })}
          total={deviceHistoryStatusList.total}
          itemPerPage={deviceHistoryPerPage}
          currentPage={deviceHistoryCurrentPage}
          onChangePage={handleChangePage}
          onChangeLimit={handleLimit}
        />
      </Box>
    </Box>
  );
}
