import { Box } from "@chakra-ui/react";
import * as React from "react";
import { MutatingDots } from "react-loader-spinner";

interface Props {
  isShow?: boolean;
}

export function TntLoading({ isShow }: Props) {
  return isShow ? (
    <Box
      w="100vw"
      h="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      zIndex="100"
      bg="rgba(0,0,0,0.7)"
    >
      <MutatingDots
        height="150"
        width="150"
        color="var(--blue-03)"
        secondaryColor="var(--blue-03)"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Box>
  ) : (
    <></>
  );
}
