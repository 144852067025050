import { useEffect, useMemo, useRef } from "react";
import _ from "lodash";

export const useDebounce = (callback: () => void, timer: number = 500) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return _.debounce(func, timer);
  }, []);

  return debouncedCallback;
};
