import { Text, TextProps } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
}

function TntHeading ({children, ...rest}: Props & TextProps) {
  return (
    <Text fontSize="36px" fontWeight="700" color="var(--black-01)" textTransform="uppercase" {...rest}>
      {children}
    </Text>
  );
}

export default TntHeading;
