import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import * as React from "react";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string | number;
  placeHolder?: string;
  onSelectOption?: (newValue: string | number) => void;
  options?: {
    value: string | number;
    label: string;
  }[];
}

function TntSelect({
  placeHolder = "Select your option",
  options,
  onSelectOption = () => {},
  value,
}: Props) {
  const { t } = useTranslation();
  const handleChangeValue = (newValue: string | number) => () => {
    onSelectOption(newValue);
  };

  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        variant="outline"
        as={Button}
        rightIcon={<IoChevronDownCircleOutline />}
      >
        {value && _.groupBy(options, "value")[value]
          ? _.groupBy(options, "value")[value][0].label
          : placeHolder}
      </MenuButton>
      <MenuList>
        {options &&
          options?.length > 0 &&
          [
            {
              value: "",
              label: t("all"),
            } as {
              value: string | number;
              label: string;
            },
          ]
            .concat(options)
            .map((ele) => {
              return (
                <MenuItem
                  key={ele?.value}
                  onClick={handleChangeValue(ele?.value)}
                >
                  {ele?.label}
                </MenuItem>
              );
            })}
      </MenuList>
    </Menu>
  );
}

export default TntSelect;
