import { Tag, Tooltip } from "@chakra-ui/react";
import { DeviceStatus } from "constants/common";
import * as React from "react";

interface Props {
  status?: string;
  payload?: any;
}

const TntStatusBlock = ({ status, payload }: Props) => {
  const getColor = () => {
    if (status === DeviceStatus.NORMAL) return "green";
    else if (status === DeviceStatus.SERVER_ERROR) return "orange";
    else if (status === DeviceStatus.DEVICE_ERROR) return "red";
    else return "gray";
  };

  return (
    <Tooltip
      hasArrow
      borderRadius="6px"
      closeOnClick={false}
      placement="left-end"
      label={
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(payload, undefined, 2)}
        </pre>
      }
    >
      <Tag colorScheme={getColor()}>
        {status} <br />
        {payload && payload.information && payload?.information?.d_fw_ver}
      </Tag>
    </Tooltip>
  );
};

export default TntStatusBlock;
