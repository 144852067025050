import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common/reducer";
import authReducer from "./auth/reducer";
import logReducer from "./log/reducer";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    log: logReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
