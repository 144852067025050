import { TntLoading } from "components/atoms/TntLoading";
import { DeviceActivityHistoryTab } from "containers/logs/deviceActivity/tabs/DeviceActivityHistoryTab";
import { DeviceActivityOverviewTab } from "containers/logs/deviceActivity/tabs/DeviceActivityOverviewTab";
import { DeviceStatusCurrentTab } from "containers/logs/deviceStatus/tabs/DeviceStatusCurrentTab";
import { DeviceStatusHistoryTab } from "containers/logs/deviceStatus/tabs/DeviceStatusHistoryTab";
import { DeviceStatusOverviewTab } from "containers/logs/deviceStatus/tabs/DeviceStatusOverviewTab";
import { useAppSelector } from "hooks/useAppSelector";
import { HomePage, LoginPage } from "pages";
import ForbiddenPage from "pages/ForbiddenPage";
import NotFoundPage from "pages/NotFoundPage";
import DeviceActivityPage from "pages/logs/DeviceActivityPage";
import DeviceStatusPage from "pages/logs/DeviceStatusPage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { commonSelector } from "state/common";

export function MainRoutes() {
  const { isLoading } = useAppSelector(commonSelector);
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(currentLanguage);
  }, [i18n]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logs">
          <Route path="device-activity" element={<DeviceActivityPage />}>
            <Route path="overview" element={<DeviceActivityOverviewTab />} />
            <Route path="history" element={<DeviceActivityHistoryTab />} />
          </Route>
          <Route path="device-status" element={<DeviceStatusPage />}>
            <Route path="overview" element={<DeviceStatusOverviewTab />} />
            <Route path="current" element={<DeviceStatusCurrentTab />} />
            <Route path="history" element={<DeviceStatusHistoryTab />} />
          </Route>
        </Route>
        <Route path="/error-403" element={<ForbiddenPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <TntLoading isShow={isLoading} />
    </>
  );
}
