import { IoHomeOutline, IoFileTrayFullOutline } from "react-icons/io5";

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export enum DeviceName {
  ENROLL = "ENROLL",
  CHECKIN = "CHECKIN",
  CHECKOUT = "CHECKOUT",
  SIDEDOOR = "SIDEDOOR",
}

export const DeviceNameOption = Object.keys(DeviceName).map((ele) => ({
  value: ele,
  label: ele,
}));

export enum DeviceStatus {
  NORMAL = "NORMAL",
  DEVICE_ERROR = "DEVICE_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
}

export const DeviceStatusOption = Object.keys(DeviceStatus).map((ele) => ({
  value: ele,
  label: ele,
}));

export enum DeviceActivityStatus {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  MISSING = "MISSING",
}

export const DeviceActivityStatusOption = Object.keys(DeviceActivityStatus).map(
  (ele) => ({
    value: ele,
    label: ele,
  })
);

export const sidebarList = [
  {
    label: "dashboard",
    destination: "/",
    icon: <IoHomeOutline fontSize="24px" />,
  },
  {
    label: "logs",
    destination: "",
    icon: <IoFileTrayFullOutline fontSize="24px" />,
    child: [
      {
        label: "device-activity",
        destination: "/logs/device-activity/overview",
      },
      {
        label: "device-status",
        destination: "/logs/device-status/overview",
      },
    ],
  },
];
